import Rails from '@rails/ujs';
Rails.start();

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import { Application } from "@hotwired/stimulus";
const application = Application.start();
application.debug = false;
window.Stimulus = application;
export { application };

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "trix";
import "@rails/actiontext";

import "../stylesheets/application.scss";

import "./replies"; // このファイルが存在することを確認
import '@interactjs/interact'; // interactjs モジュールのインポート

// Select2 の動的インポート
document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById('country-select')) {
    import('select2').then(() => {
      $('#country-select').select2({
        width: '100%' // ドロップダウンの幅を調整
      });
    });
  }
});
