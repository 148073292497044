document.addEventListener('turbo:load', setupPageFunctions);

function setupPageFunctions() {
  setupRepliesToggle();
  setupReplyButtons();
}

function setupRepliesToggle() {
  document.querySelectorAll('.show-replies-button').forEach(button => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const commentId = button.dataset.commentId;
      const repliesContainer = document.getElementById(`replies-for-comment-${commentId}`);
      toggleDisplay(repliesContainer);
    });
  });
}

function setupReplyButtons() {
  document.querySelectorAll('.reply-button').forEach(button => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const commentId = button.dataset.commentId;
      const replyFormContainer = document.getElementById(`reply-form-container-${commentId}`);
      toggleDisplay(replyFormContainer);
    });
  });
}

function toggleDisplay(element) {
  if (element.style.display === 'none') {
    element.style.display = 'block';
  } else {
    element.style.display = 'none';
  }
}
